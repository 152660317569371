<template>
	<span class="ui-icon" :style="{ width: size + 'px', height: size + 'px' }" v-bg="filename"></span>
</template>

<script>
export default {
	props: {
		name: { default: "" },
		size: { default: 24 },
	},
	computed: {
		filename() {
			const ext = ['.png', '.svg']
			const hasSomeExt = (str, ext) => ext?.some(e => str?.endsWith(e))
			return this.name + (hasSomeExt(this.name, ext) ? '' : '.svg')
		}
	}
}
</script>

<style lang="stylus" scoped>

.ui-icon
	display inline-block
	background center center no-repeat
	background-size contain
	flex-shrink 0

</style>

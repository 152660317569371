<template>
	<div ref="instance" class="journal" v-bg="cover"></div>
</template>

<script setup>
defineProps({
	cover: { default: "user/journal-cover.png", type: String }
})

const instance = ref(null)

defineExpose({
	instance
})

</script>

<style lang="stylus" scoped>

.journal
	background-size contain
	background-repeat no-repeat
	transform rotate3D(1, 1, 1, -10deg)
	cursor pointer
	transition 0.15s easeOutQuart
	&:hover
		transform rotate(-8deg) translateY(-32px)

</style>

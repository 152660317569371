<template>
	<div class="modal-difficulty-selection">
		<div class="bg"></div>
		<div class="content">

			<difficulty-selection data-id="difficulty-selection" @next="() => $emit('close')" />

		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style lang="stylus" scoped>


.modal-difficulty-selection
	absolute 0
	flex center
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 440px
		padding 32px
		background-color #fff
		border-radius 16px
		text-align center
		font-size 2rem


</style>

import { engage } from '@/engage'
import { getSuitcases } from '@/helpers/suitcase-helper'
import {createStudent, getCurrentUser, joinGroup, setUpClient} from '@/requests/home-requests'
import { useMapStore } from './map-store'
import { walkThrough } from '@affordancestudio/functions'
import {isArray, isObject, get, set} from 'lodash'

export const useUserStore = defineStore({
	id: 'user',

	state: () => ({
		user: null,
		client: null,
		userWithConfirmation: null,
		signupGame: null,
		showClosedSuitcase: false,
		showOpenedSuitcase: false,
		showItinerarySelection: true,
		userJournal: {
			visible: false,
			tab: 'meetings',
			performAction: ''
		},
		userDiscoveries: {
			visible: false,
			currentSlideIndex: 0,
			openFileId: null
		}
	}),

	getters: {
		
		isConnected() {
			return !!this.user
		},

		isAdministrator(){
			return this.isConnected && this.user?.teacher
		},

		userPreferences() {
			return this.user?.preference || {}
		},

		alternative(){
			const alternatives = this.user?.alternatives ?? []
			return alternatives?.length > 0 ? alternatives[0] : null
		},

		suitcases() {
			const children =  useMapStore().sections ?? []
			const rewardableElementIds = this.getRewardElementIds(children, 'clientFiles')
			const lootItems = this.getLootItems(this.user?.inventory ?? [], rewardableElementIds ?? [])
			return getSuitcases(lootItems ?? [])
		},
	},

	actions: {
		canDisplayModal(name) {
			const itineraryName = useMapStore()?.currentCategoryDetails?.slug
			return !this.user?.preference?.modals?.disablingByItinerary?.[itineraryName]?.includes(name)
		},
	
		async augmentPreferences({path, value}) {
			let preferences = this.user?.preference ?? {}
			let oldValue = get(preferences, path)
			let newValue

			if (isArray(value)) newValue = [...(oldValue ?? []), value.length === 1 ? value[0] : value]
			else if (isObject(value)) newValue = {...(oldValue ?? {}), ...value}
			else newValue = value

			set(preferences, path, newValue)

			await this.setUserPreferences(preferences)
		},

		async setUserPreferences(userPreferences){
			if(this.user) this.user.preference = userPreferences
			await setUserPreference({ preferences:  userPreferences})
		},

		findReward(clientGameId){
			return this.user?.inventory?.find(i => i?.clientLootItem?.clientRewards?.find(r => r?.rewardableElementId === clientGameId))
		},

		getLootItems(inventory, rewardableElementIds){
			const containsValidItem = (item) =>	item?.amount > 0 &&
										item?.clientLootItem &&
										(item?.clientLootItem?.tags ?? []).includes('docu-decouverte') &&
										(item?.clientLootItem?.clientRewards ?? []).find(r => rewardableElementIds.includes(r?.rewardableElementId))
			return inventory.filter(containsValidItem).map(({clientLootItem}) => clientLootItem)
		},
		
		getRewardElementIds(data, elementName){
			const hasDocuDecouverte = (value) => (value?.['tags'] ?? []).includes('docu-decouverte')
			function getRewardElementIdsFromElementName(elName){
				return ({value, memory}) =>{
					return (value?.[elName] ?? []).reduce((memory, file) => {
						if(hasDocuDecouverte(file))
							memory.push(file?.id)
						return memory
					}, memory)
				}
			}
			const hasElementName =(elName) => ({value}) => value?.[elName]
			return walkThrough({ action: getRewardElementIdsFromElementName(elementName), condition: hasElementName(elementName), memory: [], value: data })
		},

		async forceCheckIfConnected() {
			try{
				return this.user || await this.updateUser()
			}catch(error){ /* empty */ }
		},

		async updateUser() {
			const user = await getCurrentUser() ?? null
			if(user) await this.setUser({ user })
			return this.user
		},

		async createUserWithConfirmation({ email, password, passwordConfirm, keepContextUser }) {
			this.userWithConfirmation = await getCreateUserWithConfirmation(email, password, passwordConfirm, ['group_owner'], keepContextUser) || null
		},

		async createUserGamerWithConfirmation({ email, password, passwordConfirm, keepContextUser }) {
			this.userWithConfirmation = await getCreateUserWithConfirmation(email, password, passwordConfirm, ['visitor'], keepContextUser) || null
		},
		
		async createStudent({ groupAccessCode, keepContextUser }) {
			if(keepContextUser)
				await joinGroup({ groupAccessCode })
			else
				this.userWithConfirmation = await createStudent({ groupAccessCode })
		},

		async updateGetCurrentUser() {
			this.currentUser = await getCurrentUser() || null
		},

		async updateSetUpClient() {
			const client = await setUpClient() || null
			if (client?.user) {
				this.user = client?.user
				useTutoStore().tutos = client?.config?.tutorials || []
			}
			return this.user
		},

		async updateSignupGame({ gameId }) {
			this.signupGame = await signupGame({ gameId }) || null
		},

		async setUser({ user }) {
			this.user = user
			if (this.user) await useTutoStore().initTutos()
		},
 
		async seeItem({ id }) {
			const user = await seeInventoryItem({ id })
			if (user?.alternativeActive) await this.setUser({ user })
		},

		logout() {
			useAppStore().removeAllModals()
			useTutoStore().$reset()
			useMapStore().$reset()
			useUserStore().$reset()

			engage.logOut()
		},
		
	},
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}

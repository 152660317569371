import { getOrDefault, isValue } from "@/js/utils"
import { reactive, ref } from "vue"

export const useBottomBarManager = ({ useRouter, useUserStore, useMapStore, useAppStore, useTutoStore }) => {
	const settings			= reactive(getDefaultSettings())
	const userStore			= useUserStore()
	const appStore 			= useAppStore()
	const mapStore 			= useMapStore()
	const router				= useRouter()
	const tutoStore 		= useTutoStore()
	const buttons 			= ref([])
	const { addModal } 	= appStore

	const onUpdateSettingsByRouteName = (routeNames) =>{
		const newSettings = routeNames
			?.reduce((currentSettings, routeName) => updateSettingsByRouteName({
				routeName,
				settings: currentSettings,
				userStore,
				tutoStore,
			}), getDefaultSettings()) ?? getDefaultSettings()

		applySettings(settings, newSettings)

		buttons.value = filterButtonsBySettings(newSettings, getBtnMapping({ goToRoute: goTo(router), userStore }))
	}

	const onUpdateSettings = () => onUpdateSettingsByRouteName(getCurrentAndParentRoutesNames(router))

	watch(
		router.currentRoute, 
		currentRoute => onUpdateSettingsByRouteName(currentRoute?.matched?.map(r => r.name))
	)

	watch(
		() => userStore.user, 
		onUpdateSettings
	)

	watch(
		() => userStore?.userDiscoveries, 
		onUpdateSettings,
		{ deep: true }
	)

	watch(
		() => userStore?.userJournal,
		onUpdateSettings,
		{ deep: true }
	)

	watch(
		() => tutoStore?.currentTuto, 
		onUpdateSettings,
		{ deep: true }
	)

	onUpdateSettings()

	return {
		settings,
		buttons,
		journal						: toRefs(mapStore).journal,
		goToRoute					: goTo(router),
		openJournal				: useOpenJournal({ userStore }),
		openSuitcase			: useOpenSuitcase({ userStore, mapStore, appStore }),
		openUserSettings	: () => addModal({ slug: 'user-settings', background: 'dark-blue', scrollable: false }),
		openSoundSettings	: () => addModal({ slug: 'sound-settings', background: 'dark-blue', scrollable: false }),
	}
}

const showSignUpSettings 	= (settings) => settings.showSignUpInBtn
const showReturnBtn 			= (settings) => settings.showReturnBtn
const getBtnMapping = ({ goToRoute, userStore }) => [
	{
		name: 'Créer un compte',
		when: showSignUpSettings,
		action: () => goToRoute('signup', { query : { keepContextUser: isGuest(userStore) }})
	},
	{
		name: 'Se connecter',
		when: showSignUpSettings,
		action: () => goToRoute('login')
	},
	{
		name: 'Retour',
		when: showReturnBtn,
		action: () => goToRoute('home')
	},
]

const whenSettings = (settings) => bm => bm.when?.(settings)
const filterButtonsBySettings = (settings, btnMapping) => btnMapping?.filter(whenSettings(settings))
const getCurrentAndParentRoutesNames = (router) => router.currentRoute?._value?.matched?.map(r => r.name)

const goTo = (router) => (routeName, props = {}) => {
	playSound({ slug: 'click' })
	router.push({ name: routeName, ...props })
}

const moveToCenterOfScreen = async (el) => {
	await moveElementToCenterOfScreen({ $el: el, delay: 750, scale: 1 })
}

const useOpenJournal = ({ userStore }) => async (journalRef) => {
	playSound({ slug: 'click' })
	await moveToCenterOfScreen(journalRef)
	userStore.userJournal.visible = true
}

const useOpenSuitcase = ({ userStore, mapStore, appStore }) => async (suitcaseRef) => {
	playSound({ slug: 'click' })
	if (!userStore.suitcases.length) {
		const icon = mapStore.suitcaseDocuDecouverteIcon
		appStore.addModal({ slug: 'empty-suitcase', background: 'dark-blue', icon, scrollable: false })
	} else {
		userStore.showClosedSuitcase = true
		userStore.showOpenedSuitcase = false
		await moveToCenterOfScreen(suitcaseRef)
		userStore.userDiscoveries.visible = true
	}
}

const	getSettingsByRouteName	= (routeName) => ({ ...(bottomBarSettings[routeName]) })
const	getDefaultSettings			= () => ({ ...defaultSettings })
const	applySettings 					= (settings, newSettings) => newSettings
	?	Object.entries(newSettings).forEach(([k, v]) => {
		if(k in settings) settings[k] = v
	})
	: false
const updateSettingsByRouteName = ({ routeName, settings, userStore, tutoStore }) => {
	const mutationsMapping = [
		{
			when	: hasRouteName(routeName),
			apply	: overrideSettingsForPage(routeName)
		},
		{
			when	: 
				or(
					and(
						not(isEquals('teacher-panel', routeName)),
						not(userIsConnected(userStore)),
					),
					and(
						isEquals('home', routeName),
						userIsConnected(userStore), 
						userIsGuest(userStore)
					),
				),
			apply	: apply(
				hideUserIconBtn
			)
		},
		{
			when	: and(
				userIsConnected(userStore), 
				not(userIsGuest(userStore))
			),
			apply	: apply(
				hideSignUpInBtn
			)
		},
		{
			when	: suitcaseIsShowed(userStore),
			apply	: hideSuitcaseIconBtn
		},
		{
			when	: journalIsShowed(userStore),
			apply	: apply(hideJournalIconBtn, showJournalIconBtnSlot)			
		},
		{
			when	: or(
				userIsGuest(userStore),
				and(
					isEquals('teacher-panel', routeName),
					not(userIsConnected(userStore))
				)
			),
			apply	: grayOutUserIconBtn
		},
		{
			when	: isTutorialRunning(tutoStore),
			apply	: hideGameMenuBtn
		}
	]

	const mutationsToApply = mutationsMapping.reduce(
		(mToApply, m) => m.when() 
			? [...mToApply, m.apply]
			: m.otherwise 
				? [...mToApply, m.otherwise]
				: mToApply, 
		[])
	
	if(!mutationsToApply || mutationsToApply.length === 0) return

	return apply(...mutationsToApply)({...settings})
}
const and 	= (...conditions) => (data) => !conditions?.some(c => !c(data))
const or 	= (...conditions) => (data) => !!conditions?.find(c => c(data))
const apply = (...mutations) => (data) => data 
	? mutations?.reduce?.((d, m) => getOrDefault(() => m(d), d), data) ?? data
	: data

// --< start conditions >--
const isGuest = (userStore) => userStore?.user?.onboarding === 'is_anonymous'

// --< end conditions >--

// --< start curry function conditions >--
const isEquals 					= (val1, val2) => () => val1 === val2
const isTutorialRunning =	(tutoStore) => () => !!tutoStore.currentTuto
const hasRouteName 			= (routeName) => () => isValue(routeName)
const userIsConnected 	= (userStore) => () => !!userStore.isConnected
const suitcaseIsShowed 	= (userStore) => () => !!userStore?.userDiscoveries?.visible
const journalIsShowed 	= (userStore) => () => !!userStore?.userJournal?.visible
const userIsGuest 			= (userStore) => () => isGuest(userStore)
const not = (predicate) => (data) => !predicate?.(data)
// --< end curry function conditions >--

// --< start mutations >--
const overrideSettingsForPage = (routeName) => (data) => ({ ...data, ...(getSettingsByRouteName(routeName) ?? {})})
const hideSuitcaseIconBtn 		= (data) => ({ ...data, showSuitcase: false })
const hideJournalIconBtn 			= (data) => ({ ...data, showJournal: false })
const showJournalIconBtnSlot 	= (data) => ({ ...data, showJournalSlot: true })
const hideSignUpInBtn 				= (data) => ({ ...data, showSignUpInBtn: false })
const hideUserIconBtn 				= (data) => ({ ...data, showUserBtn: false })
const hideGameMenuBtn 				= (data) => ({ ...data, showGameMenuBtn: false })
const grayOutUserIconBtn 			= (data) => ({ ...data, grayOutUserIconBtn: true })

// --< end mutations >--


const	defaultSettings = {
	showBar								: false,
	showHomeBtn						: false,
	showUserBtn						: false,
	showJournal 					: false,
	showJournalSlot				:	false,
	showSuitcase 					: false,
	showSignUpInBtn				: false,
	showReturnBtn					: false,
	showGameMenuBtn				: false,
	showLeftSeparator			: false,
	showSoundSettingsBtn 	: false,
	grayOutUserIconBtn		: false,
}

const	bottomBarSettings = {
	'home': {
		showBar								: true,
		showUserBtn						: true,
		showSignUpInBtn				: true,
		showSoundSettingsBtn 	: true,
	},
	'teacher-panel': {
		showBar								: true,
		showHomeBtn						: true,
		showUserBtn						: true,
		showSoundSettingsBtn 	: true,
	},
	'itineraries': {
		showBar								: true,
		showHomeBtn						: true,
		showUserBtn						: true,
		showSoundSettingsBtn 	: true,
	},
	'city': {
		showBar								: true,
		showHomeBtn						: true,
		showUserBtn						: true,
		showJournal 					: true,
		showSuitcase 					: true,
		showGameMenuBtn				: true,
		showLeftSeparator			: true,
		showSoundSettingsBtn 	: true,
	},
	'login': {
		showBar								: true,
		showReturnBtn 				: true
	},
	'signup': {
		showBar								: true,
		showReturnBtn 				: true
	},
	'confirm-play': {
		showBar								: true,
		showUserBtn						: true,
		showSoundSettingsBtn 	: true,
	},
	'default': defaultSettings
}
export const useTutoStore = defineStore({
	id: 'tuto',
	
	state: () => ({
		tutos: [],
		currentTuto: null,
		forcedTutos: [],
		tutoQueue: []
	}),
	
	getters: {},
	actions: {
		async initTutos() {
			if (!this.tutos?.length) this.tutos = await getAllTutorials()
		},
		
		setCurrentTuto(value) {
			this.currentTuto = value
		}
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useTutoStore, import.meta.hot))
}

<template>
	<div class="vertical-separator"></div>
</template>

<script></script>

<style lang="stylus" scoped>

.vertical-separator
	margin-inline 2px
	width 1px
	min-width 1px
	background-color #fff
	height 65%

</style>

import { hasToken } from "@/requests/home-requests"

export function redirectUserToRouteName(user){
		const {onboarding, teacher, groups} = user
		const is = (onboarding, state) => onboarding === state
		const cases = {
				'is_teacher_validated': _ => teacher && is(onboarding, 'is_validated'),
				'is_validated': _ => is(onboarding, 'is_validated'),
				'is_confirmed': _ => is(onboarding, 'is_confirmed'),
				'is_reset': _ => is(onboarding, 'is_reset'),
				'is_in_a_group': _ => groups && groups.length > 0,
				' ': _ => true
		}

		const userCase = findAttrInObject(cases, _case => _case.value())

		const routeMapping = {
				'city': ['is_anonymous', 'is_validated'],
				'home': ['is_teacher_validated'],
				'password-reset': ['is_reset'],
				'onboarding': ['is_confirmed', 'is_in_a_group']
		}

		const route = findAttrInObject(routeMapping, route => route.value.includes(userCase?.name))
		return route?.name ?? 'home'
}

function findAttrInObject(obj, predicate){
		return Object.getOwnPropertyNames(obj)
										.map(attrName => ({name: attrName, value: obj[attrName]}))
										.find(attr => predicate(attr))
}

export class ValidationManager{
		async manage(to, from, next, providers){
				if(to.meta.validate){
						return to.meta.validate(next)
				}
				return true
		}
}

export class SessionManager{
		async manage(to, from, next, providers){
				const userStore = await providers.getUserStore()
				const isConnected = hasToken() && await userStore.forceCheckIfConnected()
				if(to.meta.shouldBeAuth && !isConnected){
						next({ name: 'login' })
						return false
				}
				return true
		}
}

export class PreloadManager{
		async manage(to, from, next, providers){
				const preload = to.meta.preload
				preload && await preload()
				return true
		}
}

export class RouterManager{
		managers = []
		providers = {}
		static setManagers(managers){
				RouterManager.managers = managers
				return RouterManager
		}
		static setProviders(providers){
				RouterManager.providers = providers
				return RouterManager
		}
		static manageRoutes(){
				return {
						beforeEach(){
								return async function (to, from, next){
										for (const manager of RouterManager.managers) {
												if(!await manager.manage(to, from, next, RouterManager.providers)){
														return
												}
										}
										next()
								}
						}
				}
		}
}

export const routerHelper = {

		validateAll: (validators) => {
				return async(next) => validators?.reduce((result, validator) => result ? validator(next) : result, true)
		},
		validators: {
				redirectIfOnboardingIs: (cases, redirectTo) => async(next) => {
						if(!cases || !redirectTo) return true
						const type = useAppStore()?.setUpClient?.user?.onboarding ?? ''
						if(cases.includes(type)){
								next({name: redirectTo})
								return false
						}
						return true
				},
				redirectIfShouldSelectItinerary: (redirectTo) => async(next) => {
						const itinerary = useUserStore()?.userPreferences?.itinerary
						if(redirectTo && !itinerary){
								next({name: redirectTo})
								return false
						}
						return true
				}
		}
}


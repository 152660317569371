<template>
	<div class="ui-scroll">
		<div ref="scroll" class="ui-scrollable" data-overlayscrollbars-initialize>
			<slot />
		</div>

		<transition name="ui-scroll-gradient">
			<div v-show="!isScrollTop" class="ui-scroll-gradient top"></div>
		</transition>
		<transition name="ui-scroll-gradient">
			<div v-show="!isScrollBottom" class="ui-scroll-gradient bottom"></div>
		</transition>

	</div>
</template>

<script>
import 'overlayscrollbars/overlayscrollbars.css'
import { OverlayScrollbars } from 'overlayscrollbars'

export default {
	data() {
		return {
			isScrollTop: true,
			isScrollBottom: false,
		}
	},
	methods: {
		scrolling(e) {
			if (!e || !e?.target) return

			if (e.target.scrollHeight > e.target.clientHeight) {
				this.isScrollTop = e.target.scrollTop < 20
				this.isScrollBottom = e.target.clientHeight + e.target.scrollTop > e.target.scrollHeight - 20
			} else {
				this.isScrollTop = true
				this.isScrollBottom = true
			}
		}
	},
	async mounted() {
		const { scrolling } = this
		OverlayScrollbars(this.$refs.scroll, {}, {
			scroll(instance, event) { scrolling(event) },
		})
		const child = this.$refs.scroll.querySelector('.os-viewport > *')
		if (child) {
			this.isScrollBottom = child.clientHeight <= this.$refs.scroll.clientHeight + 20
		} else
			this.isScrollBottom = true
	},
}
</script>

<style lang="stylus" scoped>

.ui-scroll
	height 100%
	width 100%
	overflow hidden
	.ui-scrollable
		height 100%
		overflow auto
	.ui-scroll-gradient
		position absolute
		left 0
		right 0
		height 32px
		transition 0.5s easeOutQuart
		z-index 1
		&.top
			top 0
			background linear-gradient(to bottom, alpha(#000, 35%), alpha(#000, 0%))
			&.ui-scroll-gradient-enter-from, &.ui-scroll-gradient-leave-to
				transform translateY(-100%)
		&.bottom
			bottom 0
			background linear-gradient(to top, alpha(#000, 35%), alpha(#000, 0%))
			&.ui-scroll-gradient-enter-from, &.ui-scroll-gradient-leave-to
				transform translateY(100%)

</style>

<template>
	<ui-animate name="jump">
		<ui-bottom-bar v-if="settings.showBar" id="city-user-bar" class="bottom-bar animation-footer">
			<template #header>
				<ui-inline class="show-in-desktop">
					<TransitionGroup name="fade-inline">
						<ui-button v-for="btn in buttons" :key="btn" v-tap="() => btn?.action()" :slim="true" color="dark-green">{{ btn?.name }}</ui-button>
					</TransitionGroup>
				</ui-inline>
			</template>
			
			<div class="flex gap-2">
				<ui-animate>
					<ui-icon-button 
						v-if="settings.showSoundSettingsBtn"
						id="settings-button"
						icon="audio-on"
						v-tap="() => openSoundSettings()"
					/>
				</ui-animate>
						
				<ui-animate>
					<ui-icon-button 
						v-if="settings.showUserBtn"
						:icon="settings.grayOutUserIconBtn ? 'disconnected-user-icon' : 'connected-user-icon' "
						v-tap="() => openUserSettings()"
					/>
				</ui-animate>
			</div>
			
			<ui-inline v-if="buttons?.length > 0" class="show-in-mobile mx-auto w-full" :class="{ 'mr-3' : settings?.showSoundSettingsBtn }">
				<TransitionGroup name="fade-inline">
					<ui-button v-for="btn in buttons" :key="btn" v-tap="() => btn?.action()" :slim="true" color="dark-green">{{ btn?.name }}</ui-button>
				</TransitionGroup>
			</ui-inline>

			<ui-animate>
				<ui-vertical-separator v-if="settings.showLeftSeparator"/>
			</ui-animate>

			<div class="ml-2 show-in-desktop"></div>

			<ui-animate>
				<div v-if="settings.showJournal || settings.showJournalSlot" class="journal" v-tap="onOpenJournal">
					<ui-animate>
						<ui-journal-button :cover="journal?.cover" v-if="settings.showJournal" ref="journalRef" class="journal" v-tap="onOpenJournal" />
					</ui-animate>
				</div>
			</ui-animate>

			<ui-animate>
				<ui-suitcase-button v-if="settings.showSuitcase" ref="suitcaseRef" class="-left-2" v-tap="onOpenSuitcase" />
			</ui-animate>
	
			<div class="ml-auto"></div>
				
			<ui-animate>
				<ui-icon-button
					v-if="settings.showHomeBtn"
					:class="{ '-mobile-mr-3' : settings?.showGameMenuBtn }"
					icon="home.png"
					:size="34"
					v-tap="() => goToRoute('home')"
				/>
			</ui-animate>

			<ui-animate>
				<game-menu v-if="settings.showGameMenuBtn"></game-menu>
			</ui-animate>
		</ui-bottom-bar>
	</ui-animate>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useAppStore } from "@/stores/app-store"
import { useBottomBarManager } from "@/composable/bottom-bar-manager"
import { useUserStore } from '@/stores/user-store'
import { useMapStore } from '@/stores/map-store'
import { useTutoStore } from '@/stores/tuto-store'
import { ref } from 'vue'

const { 
	settings,
	journal,
	buttons,
	openSuitcase, 
	openJournal,
	openSoundSettings,
	openUserSettings,
	goToRoute
} = useBottomBarManager({ useRouter, useUserStore, useMapStore, useAppStore, useTutoStore	 })
		
const journalRef 	= ref(null)
const suitcaseRef 	= ref(null)

const onOpenJournal 	= () => openJournal(journalRef?.value?.instance)
const onOpenSuitcase 	= () => openSuitcase(suitcaseRef?.value?.instance)
</script>

<style lang="stylus" scoped>

@import "../../assets/css/journal-button.styl"

.bottom-bar
	z-index 1
	.flex	
		#settings-button
			z-index 2

.show-in-mobile
	display none

.fade-inline-enter-active
	transition 0.35s easeOutBack 0.5s
	pointer-events none

.fade-inline-leave-active
	transition 0.35s easeInBack 0s
	pointer-events none

.fade-inline-enter-from
	opacity 0
	transform translateX(64px)
.fade-inline-leave-to
	opacity 0
	transform translateX(-64px)

#mobile
	.show-in-mobile
		display block
		flex center center
		gap 8px
		left 4px
		.ui-button
			white-space nowrap
			height auto
			padding 6px 16px
			font-size 1.6rem
		
	.show-in-desktop
		display none
</style>

<template>
	<div id="bottom-bar" class="bottom-bar">
		<header class="footer-wrap animation-footer">
			<slot name="header"></slot>
		</header>
		<slot/>
	</div>
</template>

<script></script>

<style lang="stylus" scoped>

.bottom-bar
	position absolute
	left 16px
	bottom 0
	right 16px
	border-radius 8px 8px 0 0
	background-color #05222B
	height 48px
	gap 8px
	display flex
	align-items center
	padding-inline 16px

#mobile
	.bottom-bar
		width 100%
		left 0
		border-radius 0

.footer-wrap
	position absolute
	display flex
	justify-content center
	left 0
	right 0
	top -50%
	padding-inline 16px

</style>

<template>
	<div class="game-menu">
		<character-badge class="animation" @click="popupDifficultyVisible = !popupDifficultyVisible" />
		<transition name="popup-difficulty" @after-leave="handleAfterLeave">
			<div v-if="popupDifficultyVisible" class="popup-difficulty">
				<div class="popup-difficulty-bg" @click="popupDifficultyVisible = false"></div>
				<div class="popup-difficulty-content">
					<div class="arrow" v-bg="'character-talk/bubble-arrow.svg'"></div>
					<difficulty-selection data-id="difficulty-selection" :can-replay="true" @next="() => popupDifficultyVisible = false" />

					<div v-if="showItinerarySelection">
						<hr>
						<ui-ticket-button @click="selectItinerary">Changer<br>d'itinéraire</ui-ticket-button>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useUserStore } from "@/stores/user-store"

const router = useRouter()
const userStore = useUserStore()
const { showItinerarySelection } = toRefs(userStore)
const popupDifficultyVisible = ref(false)
const shouldNavigate = ref(false)

const selectItinerary = () => {
	shouldNavigate.value = true
	popupDifficultyVisible.value = false
}

const handleAfterLeave = () => {
	if (shouldNavigate.value) {
		setTimeout(() => {
			router.push({ name: 'itineraries' })
		}, 250)
	}
}
</script>

<style lang="stylus" scoped>

.game-menu
	position relative
	border-radius 8px 8px 0 0
	width 112px
	height 100%
	.animation
		position relative
		right -12px
		top -80px
		transform-origin bottom right
		transition 0.15s easeOutQuart
		&:hover
			transform translate(-8px, -8px)

	.popup-difficulty
		position absolute
		bottom 0
		right 0
		z-index 1
		&.popup-difficulty-enter-active
			transition 0.2s easeOutQuart
			.popup-difficulty-content
				transition 0.2s easeOutBack
		&.popup-difficulty-enter-from
			.popup-difficulty-content
				transform translate(16px, 16px) rotate(4deg)
				opacity 0

		&.popup-difficulty-leave-active
			transition 0.2s easeInQuart
			.popup-difficulty-content
				transition 0.2s easeInQuart
		&.popup-difficulty-leave-to
			.popup-difficulty-content
				transform translate(-16px, 16px) rotate(-4deg)
				opacity 0

		.popup-difficulty-bg
			absolute 0
		.popup-difficulty-content
			position absolute
			right 96px
			bottom 136px
			width 340px
			text-align center
			background-color #fff
			border-radius 16px
			padding 16px 24px
			box-shadow inset 0 0 0 1px #888, 0 0 8px alpha(#000, 50%)
			transform-origin bottom center
			hr
				top -80px
				background-color dark
				border none
				height 2px
				width calc(100% - 24px)
				margin 0 auto
			.arrow
				position absolute
				top calc(100% - 1px)
				left 70%
				width 64px
				height 64px
				background left top no-repeat
				background-size contain


#mobile, #tablet-vertical, #tablet-horizontal
	@media (max-height: 860px)
		hr
			margin-top -10px

#mobile
	.game-menu
		bottom -40px
		right -24px
	.popup-difficulty
		.popup-difficulty-content
			padding-top 0
			right 24px
			bottom 168px
	.animation
		position absolute
	hr
		margin-top -24px

</style>

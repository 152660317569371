<template>
	<div>
		<div ref="instance" class="suitcase" v-bg="cover"></div>
	</div>
</template>

<script setup>

defineProps({
	cover: { default: "discoveries/closed-suitcase.svg", type: String }
})

const instance = ref(null)

defineExpose({
	instance
})

</script>

<style lang="stylus" scoped>

.suitcase
	width round(670px / 3)
	height round(540px / 3)
	bottom 7.5px
	background-size contain
	background-repeat no-repeat
	transform rotate3D(1, 1, 1, 3deg)
	cursor pointer
	transition 0.15s easeOutQuart
	margin-left -24px
	margin-right -30px
	&:hover
		transform translateY(-16px)

@media (max-width: 700px)
	.suitcase
		bottom -8px
		width round(670px / 4)
		height round(540px / 4)

@media (max-width: 480px)
	.suitcase
		width round(670px / 6)
		height round(540px / 6)
		bottom -4px
		margin-right -24px

@media (max-width: 400px)
	.suitcase
		width round(670px / 7.4)
		bottom -8px
		margin-right -30px
</style>

<template>
	<transition name="cookie-banner" appear>
		<div v-if="showCookieBanner" class="cookie-banner">
			<p @click.prevent="tapText" v-html="text"></p>
			<div class="buttons">
				<ui-button color="dark-blue" size="small" @click="accept">Accepter</ui-button>
				<ui-button color="dark-blue" size="small" @click="decline">Refuser</ui-button>
			</div>
		</div>
	</transition>
</template>

<script setup>
import { useCookieBanner } from '@/composable/cookie-banner'

const { showCookieBanner, accept, decline, tapText, text } = useCookieBanner()

</script>

<style lang="stylus" scoped>

.cookie-banner
	position fixed
	bottom 0
	right 0
	width 100%
	max-width 600px
	background-color #111
	padding 16px 24px
	border-radius 8px 0 0 8px
	z-index 2
	font-size 1.5rem
	color #eee
	&.cookie-banner-enter-active
		transition 0.25s easeOutQuart 0.5s
	&.cookie-banner-enter-from
		opacity 0
		transform translateX(64px)
	&.cookie-banner-leave-active
		transition 0.2s easeInQuart
	&.cookie-banner-leave-to
		opacity 0
		transform translateX(64px)
	> p
		margin 0 0 16px 0
		::v-deep(a)
			color inherit
			text-decoration underline
			cursor pointer
			&:hover
				text-decoration none
	.buttons
		flex end
		gap 8px
		color #111

#mobile
	.cookie-banner
		border-radius 8px 8px 0 0

</style>
<template>
	<button class="ticket-button">
		<div class="content">
			<slot />
		</div>
	</button>
</template>

<style lang="stylus" scoped>
	
.ticket-button
	height 102px
	width calc(100% - 24px)
	border-radius 16px
	background-color #144D5A
	outline none
	border none
	cursor pointer
	color #fff
	&:hover
		background-color lighten(#144D5A, 10%)
		.content
			background-color lighten(#144D5A, 6%)
	.content
		width 70%
		height 100%
		margin 0 auto
		display flex
		justify-content center
		align-items center
		background-color #0c3c4a
		border-right dotted #fff 5px
		font-size 1.8rem
		font-weight 400
		font-family Poppins

#mobile
	.ticket-button
		width calc(100% - 16px) 
		height 56px
		top -4px

#tablet-horizontal, #tablet-vertical
	@media (max-height: 860px)
		.ticket-button
			width calc(100% - 16px)

</style>

<template>
	<div v-if="characterIsHere" class="character-talk" :class="{ transparent }">
		<div class="character-talk-bg" :class="{ hidden: settings.backgroundHidden }"></div>

		<!-- PERSONNAGE EN CENTRE DE L'ECRAN -->
		<transition mode="out-in">
			<div v-if="settings.bubbleFrom === 'main-character'" class="character-talk-content">

				<div class="character" :class="{ 'has-difficulty-selection': settings.chooseDifficulty, 'tall': tallCharacter, 'short': shortCharacter }">
					<transition :name="bubbleAnimation" mode="out-in" appear>
						<tuto-bubble :key="bubbleKey" :text="visibleText" :skippable="skippable" :settings="settings" :game-result="gameResult" :voice="voice" @skip="skip" @next="() => $emit('next')" />
					</transition>
					<div :key="characterIllustration?.id" class="img" :class="{ spritesheet: characterIllustration?.tags?.includes('spritesheet') }" v-bg="characterIllustration?.base64"></div>
				</div>

				<transition name="answers" mode="out-in" appear>
					<div v-if="answers.length" class="answers" :key="bubbleKey">
						<div v-for="answer in answers" :key="answer.id" class="answer">
							<ui-button :block="true" :auto-height="true" :disabled="buttonsDisabled" :loading="loadingAnswer === answer" v-tap="() => $emit('answer', answer)"><ui-text :text="answer.text ? answer.text : answer.html ? answer.html : ''" /></ui-button>
						</div>
					</div>
					<div v-else class="buttons" :key="bubbleKey">
						<ui-button data-id-tuto="previous-btn" color="blue" aria-label="Previous button" size="small" :disabled="buttonsDisabled || !prevButton" v-tap="prev"><ui-icon name="character-talk/arrow-left.svg" /></ui-button>
						<ui-button data-id-tuto="next-btn" color="green" size="small" :disabled="buttonsDisabled || !nextButton" v-tap="next">
							<span v-if="currentTuto?.slug === 'valise-premiers_peuples-tuto'">Ouvrir</span>
							<span v-else v-html="nextButton || $t('next')"></span>
							<ui-icon name="character-talk/arrow-right.svg" /></ui-button>
					</div>
				</transition>
			</div>

			<!-- BULLE EN BAS A DROITE DE L'ECRAN -->
			<div v-else-if="settings.bubbleFrom === 'corner'" class="character-talk-corner">
				<character-badge />
				<transition :name="bubbleAnimation" mode="out-in" appear>
					<tuto-bubble :key="bubbleKey" :text="visibleText" :skippable="skippable" :settings="settings" :game-result="gameResult" :voice="voice" @skip="skip" @next="next" />
				</transition>
				<div class="buttons">
					<ui-button v-if="prevButton" data-id-tuto="previous-btn" color="blue" size="small" :disabled="buttonsDisabled || !prevButton" v-tap="prev" aria-label="Retour"><ui-icon name="character-talk/arrow-left.svg" /></ui-button>
					<ui-button data-id-tuto="next-btn" color="green" size="small" :disabled="buttonsDisabled || !nextButton" v-tap="next"><span v-html="nextButton || $t('next')"></span><ui-icon name="character-talk/arrow-right.svg" /></ui-button>
				</div>
			</div>

			<!-- BULLE EN HAUT AU MILIEU DE L'ECRAN -->
			<div v-else-if="settings.bubbleFrom === 'top'" class="character-talk-top">
				<transition :name="bubbleAnimation" mode="out-in" appear>
					<tuto-bubble :key="bubbleKey" :text="visibleText" :skippable="skippable" :settings="settings" :game-result="gameResult" :voice="voice" @skip="skip" @next="next" />
				</transition>
				<div class="buttons">
					<ui-button v-if="prevButton" data-id-tuto="previous-btn" color="blue" size="small" :disabled="buttonsDisabled || !prevButton" v-tap="prev"><ui-icon name="character-talk/arrow-left.svg" /></ui-button>
					<ui-button data-id-tuto="next-btn" color="green" size="small" :disabled="buttonsDisabled || !nextButton" v-tap="next"><span v-html="nextButton || $t('next')"></span><ui-icon name="character-talk/arrow-right.svg" /></ui-button>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'
import { urlToBase64 } from '@/js/utils'

export default {
	data() {
		return {
			bubbleAnimation: 'next',
			buttonsDisabled: false,
			characterIllustration: '',
			characters: [],
			characterIndex: 0,
			characterIsHere: false,
		}
	},
	props: {
		text: { default: "" },
		voice: { default: "" },
		characterTags: { default: [] },
		answers: { default: [] },
		bubbleKey: { default: null },
		prevButton: { default: null },
		nextButton: { default: null },
		loading: { default: false },
		loadingAnswer: { default: null },
		settings: { default: {} },
		gameResult: { default: null },
		transparent: { default: false },
		skippable: { default: false },
	},
	computed: {
		...mapState(useUserStore, [ 'user' ]),
		...mapState(useTutoStore, [ 'currentTuto' ]),
		visibleText() {
			const x = this?.user?.alternatives?.[0]
			const firstAlternative =
				x
					? this.$t('difficulties.' + x + '.titleLowerCase')
					: ''
			return this.text
				.replace('[icon-settings]', '<span class="icon-settings"></span>')
				.replace('[icon-city-character-badge]', '<span class="icon-city-character-badge"></span>')
				.replace('[icon-city-game-pin]', '<span class="icon-city-game-pin"></span>')
				.replace('[icon-city-docu-decouverte]', '<span class="icon-city-docu-decouverte"></span>')
				.replace('[icon-makwa]', '<span class="icon-makwa"></span>')
				.replace('[difficulty]', '<strong>' + firstAlternative + '</strong>')
				.replace('[contes-nord]', '<span class="contes-nord"></span>')
				.replace('[contes-ouest]', '<span class="contes-ouest"></span>')
				.replace('[contes-ontario]', '<span class="contes-ontario"></span>')
				.replace('[contes-quebec]', '<span class="contes-quebec"></span>')
				.replace('[contes-atlantique]', '<span class="contes-atlantique"></span>')
				.replace('[livre-carcajou]', '<span class="livre-carcajou"></span>')
				.replace('[livre-sedna]', '<span class="livre-sedna"></span>')
				.replace('[livre-castor]', '<span class="livre-castor"></span>')
				.replace('[livre-rose]', '<span class="livre-rose"></span>')
				.replace('[livre-paul]', '<span class="livre-paul"></span>')
				.replace('[livre-sirene]', '<span class="livre-sirene"></span>')
		},
		tallCharacter() {
			return this.characterTags?.includes('jackson-welcome-colombie-brit-sprite') || this.characterTags?.includes('jackson-explaining-colombie-brit-sprite')
		},
		shortCharacter() {
			return this.characterTags?.includes('alasie-welcome-nunavut-sprite') || this.characterTags?.includes('alasie-explaining-nunavut-sprite')
		}
	},
	watch: {
		async bubbleKey() {
			this.buttonsDisabled = true
			await wait(350 * 2)
			this.buttonsDisabled = false
		},
		characterTags: {
			handler(n, o) {
				if (!o || n[0] !== o[0]) this.fetchCharacters(n)
			},
			immediate: true
		}
	},
	methods: {
		urlToBase64,
		async fetchCharacters(characterName) {
			let media = medias.findAllByTags({ tags: [ 'character', ...this.characterTags ] })?.[0]
			if (!media && this.characterTags.length === 1) {
				media = medias.findBySlug({ slug: this.characterTags[0] })
				if (!media) {
					const character = this.characters[characterName]

					if (character && character?.base64) {
						this.characterIllustration = character
						return
					}

					media = await medias.getMediasByFilter({
						action: 'equal',
						options: 'slug',
						values: [ ...this.characterTags ],
					})

					if (media && media.length) {
						media = media[0].fold(() => {}, x => x?.data?.[0])

						if (media) {
							const {url} = media

							if (url) media.base64 = await this.urlToBase64(url)
						}
					}
				}
			}

			if (characterName && media && media?.base64) {
				this.characters[characterName] = media
				this.characterIllustration = this.characters[characterName]
				await this.$nextTick(() => this.characterIsHere = true)
			}
		},
		skip() {
			this.$emit('skip')
		},
		next() {
			if (!this.loading && !this.buttonsDisabled && this.nextButton) {
				this.buttonsDisabled = true
				this.bubbleAnimation = 'next'
				this.$emit('next')
			}
		},
		prev() {
			if (!this.loading && !this.buttonsDisabled && this.prevButton) {
				this.buttonsDisabled = true
				this.bubbleAnimation = 'prev'
				this.$emit('prev')
			}
		}
	}
}
</script>

<style lang="stylus" scoped>

#mobile
	.character-talk
		&.transparent
			.character-talk-content .character
				.img
					opacity 0
					pointer-events none
			::v-deep(.bubble) .bottom
				display none
		.character-talk-content
			width 300px
			height 300px
			.character
				left 64px
				height 220px
				width 150px
				.bubble
					width 340px
					bottom calc(100% - 48px)
					margin auto
					&.has-difficulty-selection
						bottom calc(50% + 48px)
						left calc(-50% + 12px)
						transform translate(-50%, 0)
				.img
					padding-top 4px
					&:not(.spritesheet)
						background-size 50%
						transform translate(-8px, 0)
					&.spritesheet
						transform translate(64px, 0)
						background-size (1024px * 15)
						animation idleMobile 1s steps(30) infinite
						background-origin content-box
						@keyframes idleMobile
							100%
								background-position -@background-size -32px
			.tall
				.img
					padding-top 22px
			.short
				.img
					margin-left -56px

			.buttons
				right 240px
				width auto
				gap 8px
				flex end wrap
				width 236px
		.character-talk-corner
			bottom -48px
			width calc(100vw - 48px)
			.buttons
				position fixed
				right 0
				left 0
				padding-right 120px
				width 100%
				gap 8px
			.character-badge
				right -12px
				position fixed
			.bubble
				left -32px
		.character-talk-top
			.bubble
				left calc(50% - 8px)

#desktop, #tablet-horizontal, #tablet-vertical
	@media (max-height: 820px)
		.character-talk
			.character-talk-content .character
				height 360px
				&.short
					.img
						top -220px
				&.tall
					.img
						top -48px
				.img:not(.spritesheet)
					background-size 70%
				.img
					top -48px
	@media (max-height: 700px)
		.character-talk
			.character-talk-content .character
				height 260px
				.img:not(.spritesheet)
					background-size 60%
	@media (max-height: 600px)
		.character-talk
			.character-talk-content .character
				height 200px
				.img:not(.spritesheet)
					background-size 50%


	.character-talk
		absolute 0
		.tall
			.img
				padding-top 16px
				&.spritesheet
					background-origin content-box

.character-talk
	absolute 0
	&.character-talk-enter-active
		transition 1.5s easeOutQuart
		.character-talk-content
			transition 0.25s easeOutQuart
		.answers
			.answer
				transition 0.2s easeOutBack
				for i in 1..5
					&:nth-child({i})
						transition-delay (0.8s + ((i - 1) * 0.1s))
	&.character-talk-enter-from
		.character-talk-content
			transform translate(0, 50%)
			opacity 0
		.answers
			.answer
				transform translateY(-24px)
				opacity 0

	&.character-talk-leave-active
		transition 0.25s easeInQuart
	&.character-talk-leave-to
		opacity 0

	&.transparent
		pointer-events none
		.character-talk-bg
			opacity 0

	.character-talk-bg
		absolute 0
		background linear-gradient(180deg, #4394C5EB 0%, #25495F 74%, #203C4E 100%)
		opacity 0.95
		transition opacity 0.7s easeInOutQuart 0.25s
		&.hidden
			opacity 0

	> .v-enter-active
		transition 0.25s easeOutQuart
	> .v-enter-from
		opacity 0
		transform translateY(32px)
	> .v-leave-active
		transition 0.25s easeInQuart
	> .v-leave-to
		opacity 0
		transform translateY(32px)

	.character-talk-content
		position absolute
		left 50%
		bottom 0
		width 500px
		height 500px
		.character
			position absolute
			left 0
			bottom 0
			width 256px
			height 512px
			.img
				absolute 0
				left -256px
				right -256px
				background center top -12px no-repeat
				background-size cover
				transition 0.35s easeOutQuart
				pointer-events none
				&.spritesheet
					background-size (1024px * 24)
					background-position left -32px
					animation idle 1s steps(30) infinite
					@keyframes idle
						100%
							background-position -@background-size -32px
				&.character-img-enter-active
					transition 0.35s easeOutQuart
				&.character-img-leave-active
					transition 0.15s easeOutQuart
				&.character-img-enter-from, &.character-img-leave-to
					transform translateY(32px)
					opacity 0
		.short
			.img
				top -96px
			.bubble
				position absolute
				right 35%
				bottom 90%
				width 450px
				padding 24px
				font-size 2rem
				color dark
				text-align center
				transform-origin bottom right

				&.has-difficulty-selection
					bottom 50%
					right 105%
					width 350px
					.bg .bottom
						left 75%
					.text
						font-weight 500

				&.next-enter-active
					transition 0.35s easeOutBack 0.25s
					will-change transform, opacity
				&.next-enter-from
					transform translate(32px, 32px) rotate(8deg)
					opacity 0
					will-change transform, opacity
				&.next-leave-active
					transition 0.35s easeInQuart
					opacity 0
				&.next-leave-to
					transform translate(-32px, -32px) rotate(-8deg)
					opacity 0

				&.prev-enter-active
					transition 0.35s easeOutBack 0.25s
					will-change transform, opacity
				&.prev-enter-from
					transform translate(-32px, -32px) rotate(-8deg)
					opacity 0
					will-change transform, opacity
				&.prev-leave-active
					transition 0.35s easeInQuart
					opacity 0
				&.prev-leave-to
					transform translate(32px, 32px) rotate(8deg)
					opacity 0

				.bg
					absolute 0
					border-radius 40px
					box-shadow 0 0 12px alpha(#000, 50%)
					background-color #fff
					.bottom
						position absolute
						top 100%
						left calc(50% - 32px)
						width 64px
						height 64px
						background left top no-repeat
						background-size contain

				.dots
					height 12px
					flex center
					gap 8px
					margin 24px 0 0 0
					.dot
						width 12px
						height 12px
						border 2px solid #082D37
						border-radius 50%
						&.active
							background-color #082D37
							border none

		.answers
			position absolute
			bottom 0
			right calc(100% + 16px)
			min-height 320px
			width 250px
			flex center column
			gap 16px
			&.answers-enter-active
				transition 1s easeOutBack
				pointer-events none
				.answer
					transition 0.2s easeOutBack
					for i in 1..5
						&:nth-child({i})
							transition-delay (0.8s + ((i - 1) * 0.1s))
			&.answers-enter-from
				.answer
					transform translateY(-8px)
					opacity 0
			&.answers-leave-active
				pointer-events none
				transition 0.35s easeInBack
				opacity 0
			&.answers-leave-to
				opacity 0
			.answer
				width 100%
		.buttons
			position absolute
			bottom 32px
			right calc(100% + 32px)
			width 200px
			flex end
			gap 16px
			.ui-button
				text-transform uppercase
				padding 0 16px
				white-space nowrap
				&[disabled]
					opacity 0
					cursor default

	.character-talk-corner
		position absolute
		right 0
		bottom 0
		width 140px
		height 140px
		::v-deep(.character-badge)
			pointer-events none
		.buttons
			position absolute
			right calc(100% + 16px)
			bottom 16px
			flex end
			gap 16px
			.ui-button
				text-transform uppercase
				&[disabled]
					opacity 0
					cursor default
		.bubble
			margin 24px

	.character-talk-top
		position absolute
		left 0
		right 0
		top 24px
		::v-deep(.bubble)
			bottom auto
			right auto
			top 16px
			left 50%
			transform translate(-50%, 0)
		.buttons
			position fixed
			left 0
			right 0
			bottom 16px
			flex center
			gap 16px
			.ui-button
				&[disabled]
					opacity 0
					cursor default
</style>

import { medias } from "@affordancestudio/engage-game"
import { isNoValue, transposeObject } from "@affordancestudio/functions"
export const cloneDeep = function(obj) {
	return JSON.parse(JSON.stringify(obj))
}

export function parseJson(string) {
	try {
		return JSON.parse(string) 
	} catch(err) {
		/** */
	}
}

export const isDev = () => {
	return [ 'development' ].includes(import.meta.env.VITE_APP_SPECIFIC_MODE)
}

export const getLang = () => {
	return 'fr'
}

export const isAndroid = () => {
	return navigator.userAgent.toLowerCase().indexOf("android") > -1
}

export const isTouchScreen = () => {
	return ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 )

}

export const priceFormat = (amount) => {
	return (new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format((amount || 0).toFixed(2))).replace('$', '').trim()
}

// Retourne le résultat de la promesse au minimum après le delay
export const waitPromise = async (promise, delay) => {
	const startTime = new Date()
	const response = await promise
	const remainingTime = delay - ((new Date()) - startTime)
	if (remainingTime > 0) await wait(remainingTime)
	return response
}

export const waitForCondition = (condition, callback) => {
	setTimeout(() => {
		if(condition()) {
			callback()
		}
		else {
			waitForCondition(condition, callback)
		}
	}, 500)
}

export const urlToBase64 = url => {
	return fetch(url)
		.then(response => response.blob())
		.then(blob => new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => resolve(reader.result)
			reader.onerror = reject
			reader.readAsDataURL(blob)
		}))
}

export function getOrDefault(supplier, def){
	try{
		return supplier ? supplier() : def
	}catch(err){
		console.warn(err);
		return def
	}
}

export function getImageOrDefault(imgId, defaultImgSlug){
	return getMediaOrNull(imgId)?.base64 ?? medias.findBySlug({ slug: defaultImgSlug })?.base64
}

export function getMediaOrNull(imgId){
	return imgId ? medias.get({ id: imgId }) : null
}

export function json(){
	return {
		parse(obj){
			return {
				getOrDefault(def) {
					try {
						return JSON.parse(obj)
					} catch(err) {
						return def
					}
				}
			}
		}
	}
}

export const shuffle = array => {
	let indices = Array.from(array.keys())
	
	for (let i = indices.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[indices[i], indices[j]] = [indices[j], indices[i]]
	}
	return indices.map(index => array[index])
}

export function chain(data){
	return {
		then: (callback) => data ? callback(data) : chain(data),
		get: () => data
	}
}

export function transpose(obj){

	const _get_mapping = {
		array: (arr, path) => arr?.map(x => _transpose(x).get(path)),
		object: (obj, path) => _transpose(obj).get(path)
	}

	const _find_mapping = {
		array: (arr, predicate) => arr?.find(x => predicate?.(x)),
		object: (obj, predicate) => predicate?.(obj)
	}

	const _filter_mapping = {
		array: (arr, predicate) => arr?.filter(x => predicate?.(x)),
		object: (obj, predicate) => predicate?.(obj)
	}

	const _map_mapping = {
		array: (arr, transform) => arr?.map(x => transform?.(x)),
		object: (obj, transform) => transform?.(obj)
	}

	const _flatten_mapping = {
		array: arr => arr?.filter?.(isValue).flat(),
		object: obj => obj
	}

	const _get_first_mapping = {
		array: (arr) => arr?.[0],
		object: (obj) => obj
	}

	let type = Array.isArray(obj) ? 'array' : 'object'
	const tryTransform = (mapping, type, obj, param) => obj ? transpose(mapping[type](obj, param)) : transpose(obj)

	return {
		find(predicate) {
			return tryTransform(_find_mapping, type, obj, predicate)
		},
		map(transform){
			return tryTransform(_map_mapping, type, obj, transform)
		},
		filter(predicate){
			return tryTransform(_filter_mapping, type, obj, predicate)
		},
		get: (path) => {
			return tryTransform(_get_mapping, type, obj, path)
		},
		getFirst(){
			return tryTransform(_get_first_mapping, type, obj)
		},
		flat: () => obj?.filter?.(isValue).flat(),
		flatten: () => tryTransform(_flatten_mapping, type, obj),
		log(){
			return transpose(obj)
		},
		value: obj,
	}
}

export const isValue = (x) => !isNoValue(x)

export const hasPropWithValue = (prop) => (x) => !isNoValue(x?.[prop])

function _transpose(obj){
	return {
		get(path){
			return transposeObject({
				takeAndGive: [
					{
						take: path,
						give: 'data'
					}
				],
				data: obj
			})?.['data']
		}
	}
}

export function getSuffix(text, prefix){
	return text
		? text.startsWith(prefix)
			? text.slice(prefix.length)
			: text
		: text
}

/**
 * @param {any} data
 * @param {string} key
 * @return { { [key_value] : data_without_key } }
 */
export function toDict(data, key){
	const toDict = (key, data) => ({[key]: data})
	return Array.isArray(data)
		? data.reduce((dict, { section, ...other}) => ({...dict, ...toDict(section, other)}), {})
		: toDict(key, data)
}

export const toBoolean = (data) => String(data ?? 'false') === 'true'
<template>
	<div class="modal-welcome">
		<div class="bg"></div>
		<div class="content">
			<div class="title">Bienvenue sur le jeu Lio!</div>

			<transition :name="animation" mode="out-in">
				<div v-if="step === 1" class="step step-1" key="1">
					<div class="question">
						<p><ui-text text="Est-ce la première fois que tu essaies cet itinéraire?" /></p>
					</div>
					<div class="answers">
						<ui-button data-id="not-first-time-btn" color="red" block="true" v-tap="declineTuto">Non</ui-button>
						<ui-button data-id="first-time-btn" color="green" block="true" v-tap="acceptTuto">Oui</ui-button>
					</div>
				</div>
			</transition>

		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
export default {
	inject: ['tutorialService'],
	data() {
		return {
			step: 1,
			animation: 'next'
		}
	},
	props: {
		modal: { type: Object, default: null }
	},
	emits: [ 'close' ],
	computed: {
		...mapWritableState(useAppStore, [ 'settings' ]),
		...mapState(useUserStore, [ 'userPreferences' ]),
		...mapState(useMapStore, [ 'currentCategoryId', 'currentCategoryDetails' ]),
	},
	methods: {
		...mapActions(useAppStore, ['addModal']),
		...mapActions(useUserStore, ['augmentPreferences']),
		next() {
			this.animation = 'next'
			this.step = minmax(this.step + 1, 1, 2)
		},
		prev() {
			this.animation = 'prev'
			this.step = minmax(this.step - 1, 1, 2)
		},
		async disableModal() {
			const itineraryName = this.currentCategoryDetails?.slug
			await this.augmentPreferences({
				path: 'modals.disablingByItinerary',
				value: {[itineraryName]: ['modal-welcome']},
			})
		},
		async declineTuto() {
			await this.disableModal()
			this.tutorialService.toggleEnabling({criteriaArray: [{itinerary: null}, {tag: 'onboarding'}], isEnabled: false})
			this.addModal({ slug: 'difficulty-selection', background: 'dark-blue', scrollable: false, unclosable: true })
			this.$emit('close')
		},
		async acceptTuto() {
			await this.disableModal()
			this.tutorialService.toggleEnabling({criteriaArray: [{itinerary: null}, {tag: 'onboarding'}], isEnabled: true})
			this.tutorialService.playWithParameters({ parameters: [{tag: 'onboarding'}, {tag: 'first'}]})
			this.$emit('close')
		},
	},

}
</script>

<style lang="stylus" scoped>


.modal-welcome
	absolute 0
	flex center
	.bg
		absolute 0
	> .content
		width calc(100vw - 32px)
		max-width 460px
		padding 32px
		flex center column
		gap 24px
		border-radius 8px
		color #fff
		font-size 2rem
		text-align center
		.title
			width 100%
			margin 0 0 16px 0
			font-size 3.2rem
			border-bottom 3px solid blue
		.step
			flex space-between center column
			gap 24px
			min-height 300px
			&.prev-enter-active, &.next-enter-active
				transition 0.2s easeOutQuart
				pointer-events none
			&.prev-leave-active, &.next-leave-active
				transition 0.2s easeInQuart
				pointer-events none
			&.prev-enter-from
				transform translateX(-32px)
				opacity 0
			&.prev-leave-to
				transform translateX(32px)
				opacity 0
			&.next-enter-from
				transform translateX(32px)
				opacity 0
			&.next-leave-to
				transform translateX(-32px)
				opacity 0
			.question
				width 100%
				flex center column
				gap 16px
				> p
					width 100%
			.answers
				width 100%
				flex center
				gap 16px

</style>

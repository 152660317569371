import { getMediaOrNull } from "@/js/utils";
import { walkThrough } from "@affordancestudio/functions"
import * as Yup from 'yup';

export const GLOBAL_YUP_VALIDATIONS = {
    email: yup => yup.string()
        .required('Le courriel est obligatoire')
        .matches(/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/, 'Le courriel n\'est pas valide'),
    password: yup => yup.string().required(),
    passwordConfirm: (yup, passwordFieldName) => yup.string().test({
        message(){
            return "Le mot de passe et la confirmation de mot de passe ne correspondent pas."
        },
        test(value){
            return this.parent[passwordFieldName] == value
        }
    })
}

const tags = {
    checkbox: parseCheckbox,
    dropdown: parseDropdown,
}

const types = {
    select: (value, memory) => findTagRule(tags, value?.tags) && findTagRule(tags, value?.tags)(value, memory),
    password: parsePassword,
    button: parseButton,
    text: parseText,
    deck: parseDeck,
    textInput: parseTextInput
}

const rulesTag = {
    checkbox: (value, memory) => {
        /**
         * >>>>>>>>>> WARNING <<<<<<<<<
         * 
         * Ce code est un contournement de la vrai solution.
         * Il faut changer la logique de ce code quand on pourra ajouter la règle checkbox isRequired à true côté backend.
         * Voir avec Adam pour la prochaine itération de Lio. 
         */ 
        memory[value.formElementId] = yup.array().test(
            'atLeastOneCheckboxChecked',
            null,
            (_value) => {
            return _value && _value.length > 0 
                ? true 
                : new yup.ValidationError(
                    'Please check one checkbox', // ce message n'ai pas affiché au joueur
                    null,
                    value.formElementId
                )
        })
    },
    dropdown: (value, memory) => {},
}

const rulesTypes = {
    select: (value, memory) => findTagRule(rulesTag, value?.tags) && findTagRule(rulesTag, value?.tags)(value, memory),
    password: parsePasswordFieldRules,
    textInput: parseFieldRules
}

function findTagRule(tags, tagsToFilter){
    const tagName = Object.getOwnPropertyNames(tags).find(tag => tagsToFilter.includes(tag));
    return tagName ? tags[tagName] : undefined
}

const condition = ({value}) => !!types[value?.type]

const action = (types) => ({value, memory}) => {
    types[value?.type] && types[value?.type](value, memory)
    return memory
}

function parseCheckbox(formattedElement, memory){
    memory.push({
        label: formattedElement.label,
        class: formattedElement.tag,
        as: 'checkbox',
        name: formattedElement.formElementId,
        actions: parseActionsExpressions(formattedElement.tags),
        children: (formattedElement?.options ?? []).map(opt => {
            opt.type = ''
            return {
                label: opt.value,
                value: opt.id,
                name: formattedElement.formElementId,
                actions: parseActionsExpressions(formattedElement.tags),
            }
        })
    })
    
}

function parseDropdown(formattedElement, memory){
    memory.push({
        id: formattedElement.formElementId,
        label: formattedElement.label,
        name: parseFieldName(formattedElement),
        as: 'select',
        class: formattedElement.tags?.join(' '),
        actions: parseActionsExpressions(formattedElement.tags),
        rules: parseYupRulesFromField(formattedElement, yup.string()),
        children: parseDropdownOptions(formattedElement)
    })
}

function parseDropdownOptions(formattedElement){
    const options = formattedElement.options ?? []

    return [
        {
            label: '- Sélection -',
            disabled: true,
            value: '',
            tag: 'option',
        },
        ...(options.map(opt => {
            opt.type = ''
            return {
                value: opt.id,
                label: opt.value,
                name: formattedElement.formElementId,
                tag: 'option'
            }
        }))
    ]
}


function parseActionsExpressions(tags){
    return (tags ?? []).reduce((exps, tag) => {
        const match = tag.match(/^([\w\d]+)-when-([\w\d]+)-([\w\d]+)-([\w\d]+)$/i)
        if(match)
            exps.push({
                action: match[1],
                when: {
                    field:match[2],
                    operator: match[3],
                    value: match[4]
                }
            })
        return exps
    }, [])
}


function parseButton(formattedElement, memory){
    const colorMapping = {
        'vert':'green',
        'blue':'bleu'
    }
    const clazz = getFirstOrNull(formattedElement.tags) ?? 'vert'
    memory.push({
        value: formattedElement.action,
        text: formattedElement.text,
        class: colorMapping[clazz],
        id: formattedElement.id,
        name: formattedElement.id,
        action: formattedElement.action,
        as: 'button'
    })
}

function parsePassword(formattedElement, memory){
    const fieldName = parseFieldName(formattedElement);
    memory.push({
        id: formattedElement.formElementId,
        as: 'input',
        type: 'password',
        label: formattedElement.label,
        name: fieldName,
        placeholder: formattedElement.placeholder
    })
    if(formattedElement.confirmation)
        memory.push({
            name: 'passwordConfirm' + fieldName,
            label: 'Confirmation de mot de passe',
            showValidationMsg: true,
            as: 'input',
            type: 'password',
        })
}

function parseFieldName(formattedElement) {
    return formattedElement.name ?? formattedElement.formElementId ?? formattedElement.id ?? formattedElement.label;
}

function parseFieldRules(formattedElement, memory){
    memory[parseFieldName(formattedElement)] = parseYupRulesFromField(formattedElement, yup.string())
}

function parsePasswordFieldRules(formattedElement, memory){
    const passwordFieldName = parseFieldName(formattedElement);
    memory[passwordFieldName] = parseYupRulesFromField(formattedElement, yup.string())
    if(formattedElement.confirmation)
        memory['passwordConfirm' + passwordFieldName] = GLOBAL_YUP_VALIDATIONS.passwordConfirm(yup, passwordFieldName)
}

function parseText(formattedElement, memory){
    const lastField = getLastElement(memory)
    if (lastField && isDescription(formattedElement))
        lastField.description = formattedElement.value
    else
        memory.push({
            label: formattedElement.label,
            text: formattedElement.value,
            class: getFirstOrNull(formattedElement.tags),
            icon: getIconOrNull(formattedElement.tags),
            as: 'text',
            name: parseFieldName(formattedElement)
        })
}

function getIconOrNull(tags){
    const icons = {
        'averstissement': 'warn'
    }

    return tags == null ? null : icons[Object.keys(icons).find(i => tags.includes(i)) ?? '']
}

function getLastElement(array){
    if (array.length == 0) return null
    return array[array.length - 1]
}

function isDescription(formattedElement){
    return formattedElement.tags?.includes('description')
}

function parseDeck(formattedElement, memory){
    memory.push({
        id: formattedElement.formElementId,
        as: 'deck',
        label: formattedElement.label,
        name: parseFieldName(formattedElement),
        cards: (formattedElement.cards ?? []).map(parseCard)
    })
}

function parseCard(card){
    return {
        id: card.id,
        label: card.document?.title,
        img: getMediaOrNull((card.medias ?? [])[0]?.value)?.base64
    }
}


function getFirstOrNull(array){
    return array && array.length > 0 ? array[0] : null
}

function parseTextInput(formattedElement, memory){
    memory.push({
        label: formattedElement.label,
        class: getFirstOrNull(formattedElement.tags),
        type: 'text',
        as: 'input',
        name: parseFieldName(formattedElement),
        placeholder: formattedElement.placeholder
    })
}

function parseYupRulesFromField(formattedElement, yup){
    const matchFieldValidationToYupRule = {
        isRequired: (value, yup) => value ? yup.required() : yup,
    }

    return getYupRulesFromMapping(matchFieldValidationToYupRule, formattedElement, yup)
}

function getYupRulesFromMapping(matchFieldValidationToYupRule, formattedElement, yup) {
    return Object.getOwnPropertyNames(matchFieldValidationToYupRule).reduce((yup, currentValidation) => {
        const fieldValidationValue = formattedElement[currentValidation]
        return fieldValidationValue ? matchFieldValidationToYupRule[currentValidation](fieldValidationValue, yup) : yup
    }, yup);
}

export function parseFormattedElementsToDynamicFormSchema(formattedElements){
    return {
        validationSchema: yup.object().shape(walkThrough({action: action(rulesTypes), condition, memory: {}, value: formattedElements})),
        fields: walkThrough({action: action(types), condition, memory: [], value: formattedElements})
    }
}

export function parseFormDataKeyValueToAnswer(dataKeyValue){
    return Object.getOwnPropertyNames(dataKeyValue).reduce((answer, key) => {
        const data = dataKeyValue[key]
        if(isPasswordConfirm(key)){
            const passwordFieldName = key.substring('passwordConfirm'.length)
            const password = answer.find(el => el.formElementId == passwordFieldName)
            password.data.push(data)
        }else
            answer.push({
                formElementId: key,
                data: Array.isArray(data) ? data : [data]
            })
        return answer
    }, [])
}

function isPasswordConfirm(key){
    return key.includes('passwordConfirm')
}

export const yup = Yup

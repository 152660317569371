<template>
	<div class="difficulty-selection" :class="{ 'has-selection': selectedDifficulty, 'full-height' : !canReplay }">

		<template v-if="!selectedDifficulty">
			<div class="text">
				<p><strong><ui-text text="Choisis un parcours:" /></strong></p>
			</div>
			<div class="difficulties">
				<div v-for="difficulty in difficulties" :key="difficulty" data-id="difficulty-btn" class="difficulty" :class="{ current: user?.alternatives?.includes(difficulty) }" v-tap="() => selectDifficulty(difficulty)">
					<div class="icon" :class="difficulty" v-bg="'difficulty-' + difficulty + '.svg'"></div>
					<p><strong>{{ $t('difficulties.' + difficulty + '.title') }}</strong></p>
					<p>{{ $t('difficulties.' + difficulty + '.subtitle') }}</p>

					<div v-if="user?.alternatives?.includes(difficulty)" title="Votre difficulté actuelle" class="check" v-bg="'check.svg'"></div>
				</div>
				<button v-if="canReplay" class="replay-btn" @click="restartTuto">Voir le tutoriel</button>
			</div>

		</template>

		<template v-else>
			<div class="text">
				<p>Tu as choisi le parcours<strong><br>{{ $t('difficulties.' + selectedDifficulty + '.title') }}</strong>!</p>
			</div>
			<div class="difficulty">
				<div class="icon" :class="selectedDifficulty" v-bg="'difficulty-' + selectedDifficulty + '.svg'"></div>
				<p><strong>{{ $t('difficulties.' + selectedDifficulty + '.title') }}</strong></p>
				<p>{{ $t('difficulties.' + selectedDifficulty + '.subtitle') }}</p>
			</div>
			<div class="buttons">
				<ui-button size="small" aria-label="Previous button" color="blue" v-tap="back"><ui-icon name="character-talk/arrow-left" /></ui-button>
				<ui-button data-id="start-btn" size="small" color="green" :loading="loading" v-tap="submit"><span v-if="!loading">C'est parti!</span><ui-icon name="character-talk/arrow-right.svg" /></ui-button>
			</div>
			<ui-audio-player-button v-if="voiceUrl" :audio-url="voiceUrl" />
		</template>

	</div>
</template>

<script>
import { medias } from '@affordancestudio/engage-game'

export default {
	inject: ['tutorialService'],
	data() {
		return {
			loading: false,
			selectedDifficulty: null,
			difficulties: [ 'debutant', 'intermediaire', 'avance' ],
		}
	},
	props: {
		canReplay: { default: false, type: Boolean },
		showTutoButton: { default: false, type: Boolean }
	},
	computed: {
		...mapState(useUserStore, [ 'user' ]),
		...mapWritableState(useUserStore, [ 'showItinerarySelection' ]),

		voiceUrl() {
			if (this.selectedDifficulty) {
				const type = { debutant: 'excursion', intermediaire: 'expedition', avance: 'aventure' }[this.selectedDifficulty]
				if (type) return medias.findBySlug({ slug: 'Sasha-choix-confirmation-' + type })?.base64
			}
			return null
		}
	},
	methods: {
		...mapActions(useUserStore, [ 'updateUser' ]),
		...mapActions(useMapStore, [ 'updateMap' ]),
		...mapActions(useAppStore, [ 'addModal' ]),
		...mapActions(useTutoStore, [ 'replayTuto' ]),

		restartTuto() {
			this.$emit('next')
			let parameters = [
				{tag: 'onboarding'}
			]
			this.tutorialService.replay({parameters})
			this.tutorialService.playWithParameters({parameters: [{tag: 'onboarding'}, {tag: 'first'}]})
		},
		selectDifficulty(difficulty) {
			playSound({ slug: 'click' })
			this.selectedDifficulty = difficulty
			this.showItinerarySelection = false
		},
		back() {
			this.selectedDifficulty = null
			this.showItinerarySelection = true
		},
		playTuto() {
			this.$emit('next')
			this.addModal({ slug: 'tuto-play', background: 'dark-blue', scrollable: false })
		},
		async submit() {
			if (!this.loading) {
				this.loading = true

				if (this.user?.alternatives?.length) {
					await Promise.all(this.user?.alternatives?.map(alternative => removeAlternative({ alternative }) ))
					await addAlternative({ alternative: this.selectedDifficulty })
				} else {
					await addAlternative({ alternative: this.selectedDifficulty })
				}

				await Promise.all([ this.updateUser(), this.updateMap() ])
				this.selectedDifficulty = null
				this.$emit('next')

				this.loading = false
			}
		},
	},
	mounted() {
		this.showItinerarySelection = true
	},
}
</script>

<style lang="stylus" scoped>

smallVersion()
	.difficulty-selection
		min-height 346px
		padding 8px
		&:not(.has-selection)
			> .text
				font-size 1.8rem
				margin-top -40px
			.difficulties
				gap 8px
				padding-left 16px
				top -40px
				.difficulty
					width 100%
					padding 8px 16px
					.icon
						left -16px
						top calc(50% - 24px)
						width 48px
						height 48px
						background-size 32px
					.check
						top 12px
						right 0
	.replay-btn
		width calc(100% + 16px)
		left -8px
		top 14px
		height 56px

	.difficulty-selection
		&.full-height
			.text
				top 16px
			.difficulties
				top 0

#mobile
	smallVersion()

#desktop, #tablet-horizontal, #tablet-vertical
	@media (max-height: 860px)
		smallVersion()

.difficulty-selection
	width 100%
	flex center center column
	gap 16px
	padding-bottom 32px
	&.has-selection
		gap 40px
		.difficulty
			width 100%
			font-size 2rem
			line-height 1.3em
			padding-top 48px
			.icon
				width 80px
				height 80px
				left calc(50% - 40px)
				top -40px

	.text
		width 100%
		margin 0 0 32px 0
	.difficulties
		width 100%
		flex start center column
		gap 48px
		padding-bottom 8px
		.difficulty
			background-color #0F4C5C
			&.not-available
				background-color #93ABB2
				cursor not-allowed
				filter grayscale(1)
				.icon
					background-color #d2a586
					box-shadow 0 0 0 3px #93ABB2
				.soon
					opacity 0.5
			&:not(.not-available)
				cursor pointer
				&:hover
					background-color lighten(@background-color, 10%)
					.icon
						box-shadow 0 0 0 3px @background-color
				&:active
					background-color @background-color
					transform translateY(2px)
					.icon
						box-shadow 0 0 0 3px @background-color
			&.current
				.check
					position absolute
					right -4px
					top -4px
					width 32px
					height 32px
					background #fff center center no-repeat
					border-radius 50%
					box-shadow 0 0 0 4px #0F4C5C

	.difficulty
		width calc(100% - 24px)
		padding 40px 16px 16px 16px
		background-color #0F4C5C
		border-radius 16px
		color #fff
		font-size 1.8rem
		line-height 1.3em
		.icon
			position absolute
			left calc(50% - 32px)
			top -32px
			width 64px
			height 64px
			border-radius 50%
			background #999 center center no-repeat
			background-size 56px
			box-shadow 0 0 0 3px #0F4C5C
			&.debutant
				background-color #42A854
			&.intermediaire
				background-color #FAB62D
				background-size 64px
			&.avance
				background-color #D9620F
		> p
			width 100%
			strong
				font-weight 500
	.replay-btn
		top -24px
		width calc(100% - 24px)
		border none
		outline none
		cursor pointer
		padding 16px
		margin-bottom -56px
		background-color #15758E
		color #fff
		border-radius 16px
		font-size 1.8rem
		font-family Poppins
		&:hover
			background lighten(#15758E, 15%)

	.buttons
		flex center
		gap 12px
		top -24px

	.ui-audio-player-button
		position absolute
		right -32px
		top -32px

</style>

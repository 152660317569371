<template>
	<div class="character-badge">
		<div class="illustration" v-bg="characterIllustration"></div>
	</div>
</template>

<script setup>
import { useItineraries } from '@/composable/itineraries';

const {
	characterIllustration
} = useItineraries()

</script>

<style lang="stylus" scoped>
$size = 140px
$mSize = 96px
#mobile
	.character-badge
		width $mSize
		height $mSize

.character-badge
	width $size
	height $size
	border-radius 50% 50% 0 50%
	background-color #0F4B5A
	box-shadow 0 0 0 8px #05222B
	cursor pointer
	.illustration
		absolute 0
		border-radius 50% 50% 0 50%
		background center center no-repeat
		background-size contain

</style>

import { engage } from '@/engage'
import { medias } from '@affordancestudio/engage-game'

export const getSection = async ({ name, category = JSON.stringify({ value: '6578836dc54fa0000b100870' })}) => {
	return await get('mySection', { id: name, category })
}

export const myMap = async ({ category = JSON.stringify({ value: '6578836dc54fa0000b100870' })}) => {
	return await get('myMap', { category })
}

export const mainSection = async () => {
	return await get('mainSection')
}

export const hasToken = () => {
	try{
		const appId = window.localStorage.getItem('Stack-Client')
		const store = JSON.parse(window.localStorage.getItem(appId))
		return store['refreshToken'] ?? false
	}catch(err){
		return false
	}
}

export const getCurrentUser = async () => {
	return await get('currentUser')
}

export const getCreateUserWithConfirmation = async (email, password, passwordConfirmation, roles, keepContextUser = false) => {
	return await post('createUserWithConfirmation', { input: { email, password, passwordConfirmation, roles }, keepContextUser }, {}, (e) => {throw e})
}

export const joinGroup = async ({ groupAccessCode }) => {
	return await post('joinGroup', { groupAccessCode }, {}, (e) => {throw e});
}

export const getOnboarding = async () => {
	return await get('onboarding')
}

export const getPasswordReset = async () => {
	return await get('reset')
}

export const cookieBanner = async() => {
	return await get('cookieBanner')
}

export const setUpClient = async () => {
	return await get('setUpClient')
}

export const categories = async () => {
	return await get('categories')
}

export const login = async ({ email, password }) => {
	return (await engage.logIn({ email, password })).fold(e => {throw e}, (x) => x)
}

export const loginStudent = async ({ userName, password }) => {
	await getResponseOrThrowError(async () => await engage.loginStudent({ userName, password }), 'login_student_error')
}

async function getResponseOrThrowError(request, errorMsg = 'server.error'){
	const res = await request()
	if(!res) throw new Error(errorMsg)
}

export const createStudent = async ({ groupAccessCode }) => {
	return await getResponseOrThrowError(async () => await engage.createStudent({ groupAccessCode }), 'create_student_error')
}

export const signup = async ({ email, password, firstname }) => {
	return await post('signUp', { input: { email, password, firstName: firstname } })
}

export const signupGame = async ({ gameId }) => {
	return await post('signupGame', { gameId })
}

export const requestPasswordReset = async ({ email }) => {
	return await post('requestPasswordReset', { email })
}

export const addAlternative = async ({ alternative }) => {
	return await post('addAlternative', { alternative })
}

export const removeAlternative = async ({ alternative }) => {
	return await post('removeAlternative', { alternative })
}

export const switchAlternative = async ({ alternative }) => {
	return await post('switchAlternative', { alternative })
}

export const seeInventoryItem = async ({ id }) => {
	return await post('seeInventoryItem', { clientLootItemId: id })
}

export const evaluate = async ({ elementType, containerId, comment }) => {
	return await post('evaluate', { elementType, containerId, comment })
}

export const setUserPreference = async ({ preferences }) => {
	return await post('setPreference', { preference: JSON.stringify(preferences) })
}

export const loadMedias = async () => {
	console.info('🌄 INIT DISTANT MEDIAS')
	medias.initDB({ dbName: 'lio', version: 1 })
	await medias.load()
}

export const resendConfirmation = async ({ email }) => {
	return await post('resendConfirmation', { email })
}
<template>
	<div class="ui-close-button" :class="color" data-id="close">
		<ui-icon name="close" :size="32" />
	</div>
</template>

<script>
export default {
	props: {
		color: { default: '' }
	}
}
</script>

<style lang="stylus" scoped>

.ui-close-button
	position fixed
	right 8px
	top 8px
	width 56px
	height 56px
	flex center
	cursor pointer
	&:active
		transform scale(0.9)
	
	&.light
		background-color #DEDEDE
		border-radius 50%
		right 12px
		top 12px
		width 48px
		height 48px
		.ui-icon
			filter brightness(0.5)
			width 24px !important
			height 24px !important

</style>
